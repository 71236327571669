<template>
  <div class="content">
    <tabs>
      <tab :name="$tc('edit', 2)" :selected="true">
        <CustomerEdit
          :customer="customer"
          :customerPicture="customerPicture"
          :customerType="customerType"
          @editCustomer="getItem"
        />
      </tab>
      <tab
        v-if="customer.bookings && customer.bookings.length > 0"
        :name="$tc('appointment', 2)"
      >
        <CustomerAppointments :customerBookings="customer.bookings" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from "../../components";
import CustomerEdit from "./CustomerEdit";
import CustomerAppointments from "./CustomerAppointments";

export default {
  name: "CustomersManagement",
  data: () => {
    return {
      id: null,
      route: "/customers",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      customer: {
        name: null,
        email: null,
        picture: null,
        comment: null,
      },
      customerType: null,
      customerPicture: null,
      isModalShow: false,
      isSpinnerShow: true,
    };
  },
  components: {
    Tabs,
    Tab,
    CustomerEdit,
    CustomerAppointments,
  },
  methods: {
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.customer = response.data.data.customers;
        this.customerType = this.customer.type;
        this.customerPicture = this.customer.picture;
        this.isSpinnerShow = false;
      }
    },
  },
  beforeMount() {
    this.id = this.$route.params.id;
    if (this.id != null) {
      this.getItem();
    } else {
      this.isSpinnerShow = false;
    }
  },
};
</script>

<style scoped></style>
