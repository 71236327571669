<template>
  <div :class="!id ? 'content' : ''">
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.customer") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
          $t("cancel")
        }}</base-button>
        <base-button type="danger" @click="deleteItem">{{
          $t("delete")
        }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header"> 
            <div class="col d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button
                    type="cancel"
                    @click="$router.push({ name: 'customerList' })"
                >{{$t("goBack") }}</base-button>
              </div>
              <div v-if="isManager || isEmployee">
                <base-button 
                    v-if="id != null &&!isSpinnerShow && customerType === 1"
                    type="danger"
                    class="mr-2"
                    @click="isModalShow = true"
                >{{ $tc("delete") }}</base-button
                >
                <base-button
                    :disabled="isBtnDisabled"
                    native-type="submit"
                    type="success"
                    form="editCustomerForm"
                >{{ $t("save") }}</base-button
                >
              </div>
            </div>
            <div class="col d-block d-md-flex justify-content-between">
              <div class="d-flex">
                <h1
                  v-if="id == null && (isManager || isEmployee)"
                  class="card-title mb-0"
                >
                  {{ $t(`routes.${this.$route.name}`) }}
                </h1>
                <h1 v-if="id != null && (isManager || isEmployee)" class="mb-4 mb-md-0 d-flex align-items-center">
                  {{ customer.name }}
                </h1>
              </div>
              <CustomerWidget
                v-if="id && !isSpinnerShow"
                :bookings="customer.bookings"
              />
            </div>
          </template>
          <form
            class="col py-4"
            @change="disabledBtn(false)"
            @submit.prevent="manageItem()"
            id="editCustomerForm"
          >
            <hr class="my-1" />
            <div v-if="!isSpinnerShow" class="py-4">
              <div class="row mb-4">
                <div class="col-12 col-md-6">
                  <base-checkbox v-model="customer.pivot.is_blocked">
                    {{ $t("userBlocked") }}
                  </base-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    max="255"
                    v-model="customer.name"
                    :placeholder="$t('name')"
                    :label="`${$t('name')}*`"
                    required
                  ></base-input>
                </div>

              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    inputType="email"
                    v-model="customer.email"
                    :placeholder="$t('email')"
                    :label="`${$t('email')}`"
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    inputType="tel"
                    v-model="customer.phone"
                    :placeholder="$t('phone')"
                    :label="`${$t('phone')}*`"
                    required
                  ></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-textarea
                      v-model.trim="customer.pivot.comment"
                      :placeholder="$t('notesAboutCustomer')"
                      :label="`${$t('notesAboutCustomer')}`"
                  ></base-textarea>
                </div>
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
                    $t("picture")
                  }}</label>
                  <div class="d-flex justify-content-center d-md-block">
                    <image-upload
                      ref="customer-picture"
                      v-if="customerType === 1 || $route.name === 'newCustomer'"
                      @change="onImageChange"
                      :src="customer.picture"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="customer"
                    />
                    <avatar
                      v-else
                      :src="customerPicture"
                      :username="customer.name"
                      :rounded="false"
                      :size="250"
                    ></avatar>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center py-5">
              <Spinner></Spinner>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseCheckbox,
  BaseInput,
  Card,
  ImageUpload,
  Modal,
  Spinner,
  BaseTextarea,
} from "@/components/index";
import Avatar from "vue-avatar";
import { mapState, mapActions } from 'vuex';
import CustomerWidget from "./Components/CustomerWidget";

export default {
  name: "CustomerEdit",
  props: ["customer", "customerPicture", "customerType"],
  data: () => {
    return {
      id: null,
      roleId: 2,
      route: "/customers",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      picture: null,
      isModalShow: false,
      isSpinnerShow: true,
      inputFiles: ['customer-picture']
    };
  },
  computed: {
    ...mapState(["isManager", "isEmployee"]),
  },
  methods: {
    createItem() {
      let customer = new FormData();
      customer.append("name", this.customer.name);
      if(this.customer.email !== null && this.customer.email !== ''){
        customer.append("email", this.customer.email);
      }
      customer.append("phone", this.customer.phone);
      if(this.customer.pivot.comment !== null){
        customer.append("comment", this.customer.pivot.comment);
      }
      customer.append("is_blocked", this.customer.pivot.is_blocked? 1 : 0);
      if (this.picture) customer.append("media", this.picture);
      return customer;
    },
    manageItem() {
      const customer = this.createItem();
      if (this.id != null && (this.isManager || this.isEmployee)) {
        customer.append("_method", "PUT");
        this.updateItem(customer);
        return;
      }
      this.storeItem(customer);
    },
    onImageChange(file) {
      this.picture = file;
    },
    async storeItem(customer) {
      const response = await this.axios.post(this.route, customer);
      if (response && response.data.status === "success") {
        this.disabledBtn(true);
        await this.$router.push({ name: "customerList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    controlSpinnerShow() {
      this.customer
        ? (this.isSpinnerShow = false)
        : (this.isSpinnerShow = true);
    },
    async updateItem(customer) {
      this.disabledBtn(true);
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.post(route, customer);
      if (response && response.data.status === "success") {
        this.$emit("editCustomer");
        this.$toast.success(this.$t("notifications.success.general"));
        
        if(this.customerType === 2) return;
        this.clearInputFiles();
      }
    },
    async deleteItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        this.$router.push({ name: "customerList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled );
    },
    clearInputFiles() {
      this.inputFiles.forEach( inputFile => {
        if(!this.$refs || !inputFile) return;

        this.$refs[inputFile]?.cleanInput()
      });
    },
    ...mapActions(["setHasChangeForm"])
  },
  beforeMount() {
    this.id = this.$route.params.id;
    if (this.id === null) {
      this.isSpinnerShow = false;
    }
  },
  watch: {
    customer() {
      this.controlSpinnerShow();
    },
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    ImageUpload,
    Modal,
    Spinner,
    Avatar,
    BaseTextarea,
    CustomerWidget,
  },
};
</script>

<style scoped>
</style>
